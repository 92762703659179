<template>
  <div>
    <h1 style="text-align: center;" class="mt-10">Der Verein</h1>
    <TextField :text="text1" />
    <h2 style="text-align: center;">Historie </h2>
    <TextField :text="text2" />
    <h2 style="text-align: center;">Das Zeppelin-Stadion (VfB-Stadion) </h2>
    <TextField :text="text3" />
    <Carousel :items="items" />
  </div>
</template>

<script>
import TextField from '@/components/article/TextField.vue'
import Carousel from '@/components/images/carousel.vue'

export default {
  name: 'DerVerein',

  components: {
    TextField,
    Carousel
  },

  data: () => ({
    text1: 'Der VfB Friedrichshafen | Leichtathletik ist die Leichtathletik-Abteilung des VfB Friedrichshafen e.V.. Wir bieten unseren Mitgliedern ein vielfältiges Programm, das alle Generationen verbindet, angefangen von der Bambini-Leichtathletik bis zum Sport für Ältere.  Im Mittelpunkt unserer Trainingsinitiativen steht die Freude am Sport in der Gemeinschaft. Dabei reichen die Trainings-inhalte vom reinen Breitensport und der Leichtathletik-Grundausbildung über den  Leistungs-sport für Kinder und Jugendliche bis hin zur Spitzenleichtathletik auf Weltklasseniveau. Egal, ob Sie körperlich fit bleiben möchten, ein Sportabzeichen erwerben oder als ambitionierter Athlet Träume verwirklichen wollen. Der VfB LC bietet allen Leichtathletikbegeisterten ein Zuhause. Wir freuen uns auf Euch!',
    text2: 'Die Abteilung Leichtathletik des VfB Friedrichshafen besteht seit 1952.  Nachdem sich VfB und LAC Friedrichshafen wieder zusammenschlossen, bildet sie unter dem Namen VfB Leicht-athletikclub Friedrichshafen eine feste Größe im Sportgeschehen der Stadt.\n\nDas erste leichtathletische Sportfest fand im Jahre 1912 statt. Weltbekannt wurde die Zeppelin-stadt durch Armin Harys Weltrekordlauf 1958 über 100 Meter in 10,0 Sekunden, der wegen 1cm zu viel Bahngefälle leider nicht anerkannt werden konnte. Seit 2013 brachte der Ausnahme-läufer Richard Ringer mit mehreren nationalen Titeln und internationale Erfolgen im 5000m-Lauf den VfB LC wiederholt in die Schlagzeilen.',
    text3: "Das Zeppelin-Stadion ist das eigentliche \"Zuhause\" des VfB Leichtathletikclubs. Hier finden die zahlreichen Trainingseinheiten, Versammlungen und Wettkämpfe statt. Das Zeppelin-Stadion wird auch 'VfB-Stadion' genannt. Das Stadion wurde im Jahr 1998 komplett saniert. Heute verfügt der VfB Leichtathletikclub über eine hochmoderne Sportanlage. Hervorzuheben ist die Sprinterrampe mit 110m Länge und 2% Steigung, ein modernes Trainingsmittel für die Sprinter und andere Sportarten zur Kräftigung und Schonung der Muskulatur. Sie ist eine Spende der Firma Kutter in Memmingen.",
    items: [
      { src: require('@/assets/bahn-tri.jpg') },
      { src: require('@/assets/vereinsheim.jpg') },
      { src: require('@/assets/nordkurve.jpg') }
    ]
  })
}
</script>
