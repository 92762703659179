<template>
  <div>
    <Parallax />
    <TrainingGrid />
    <BannerMV />
    <h1 style="text-align: center;" class="mt-5">Aktuelles:</h1>
    <Artikel :pictures="articles[0].pictures" :title="articles[0].title" :text="articles[0].text" />
    <Hexagons />
    <Carousel :items="items" />
    <TextField :text="text" />
  </div>
</template>

<script>
import Carousel from '@/components/images/carousel.vue'
import Artikel from '../components/article/Artikel.vue'
import TextField from '@/components/article/TextField.vue'
import articles from '@/assets/config/articles.json'
import Parallax from '@/components/images/parallax.vue'
import Hexagons from '@/components/images/hexagons.vue'
import TrainingGrid from '@/components/images/trainingGrid.vue'
import BannerMV from '@/components/navigation/bannerMV.vue'

export default {
  name: 'Startseite',

  components: {
    Carousel,
    Artikel,
    TextField,
    BannerMV,
    Parallax,
    Hexagons,
    TrainingGrid
  },
  data: () => ({
    articles,
    text: 'Der VfB Friedrichshafen | Leichtathletik ist die Leichtathletik-Abteilung des VfB Friedrichshafen e.V. Wir bieten unseren Mitgliedern ein vielfältiges Programm, das alle Generationen verbindet, angefangen von der Bambini-Leichtathletik bis zum Sport für Ältere.\n\nIm Mittelpunkt unserer Trainingsinitiativen steht die Freude am Sport in der Gemeinschaft. Dabei reichen die Trainingsinhalte vom reinen Breitensport und der Leichtathletik-Grundausbildung über den Leistungssport für Kinder und Jugendliche bis hin zur Spitzenleichtathletik auf Weltklasseniveau.',
    items: [
      { src: require('@/assets/carousel/13.07.2020-2000x1429.jpg') },
      { src: require('@/assets/carousel/13.07.20201-1-2000x1333.jpg') },
      { src: require('@/assets/carousel/13.07.20202-1-2000x1333.jpg') },
      { src: require('@/assets/carousel/DSC_0017.jpg') },
      { src: require('@/assets/carousel/DSC04631.jpg') },
      { src: require('@/assets/carousel/DSC07805.jpg') }
    ]
  })
}
</script>
