<template>
  <div>
    <nav>
      <v-toolbar flat dense :color="color('VfbBlau')" class="hidden-md-and-up">
        <v-btn icon class="white--text" @click="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">VfB Friedrichshafen | Leichtathletik</v-toolbar-title>
      </v-toolbar>
      <v-navigation-drawer app left :width="325" :value="$vuetify.breakpoint.smAndDown ? drawer : true"
      :mini-variant="$vuetify.breakpoint.smAndDown ? false : mini" :expand-on-hover="$vuetify.breakpoint.mdAndUp"
        @update:mini-variant="toggleExpanded">
        <v-list>
          <router-link :to="{ name: 'startseite' }" style="text-decoration: none;">
            <v-list-item class="px-2" link>
              <v-list-item-avatar rounded>
                <v-img src="@/assets/VfB-Logo-T.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-h6">VfB Friedrichshafen | Leichtathletik</v-list-item-title>
              <v-btn icon @click.stop="drawer = !drawer" class="hidden-md-and-up ml-5" fab elevation="2" small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-list-item>
          </router-link>
        </v-list>

        <v-divider></v-divider>

        <v-list dense nav>
          <div v-for="(item, i) in items" :key="item.title">
            <v-list-item v-if="!item.subItems" :key="i" :to="item.to" class="v-list-item">
              <v-list-item-action>
                <v-icon :color="color('VfbBlau')">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-body-1" >{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-else :key="item.title">
              <template v-slot:activator>
                <v-list-item class="ml-0 pl-0">
                  <v-list-item-action>
                    <v-icon :color="color('VfbBlau')">{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-body-1" >{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item v-for="subItem in item.subItems" :to="subItem.to" :key="subItem.title" class="ml-2"
                v-show="$vuetify.breakpoint.smAndDown ? true : expanded">
                <v-list-item-title class="text-body-1" >{{ subItem.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>

      </v-navigation-drawer>
    </nav>
  </div>
</template>

<script>
import { getColor } from '@/scripts/colorHelper'

export default {
  name: 'App',
  data () {
    return {
      drawer: false,
      expanded: false,
      mini: undefined,
      items: [
        {
          title: 'Trainingsgruppen',
          icon: 'mdi-account-group',
          subItems: [
            { title: 'Bambini (5 bis 7)', to: '/bambini' },
            { title: 'Kinder (7 bis 10)', to: '/kinder' },
            { title: 'Jugend (11 bis 14)', to: '/jugend' },
            { title: 'Jugend und Aktive (ab 15)', to: '/jugendUndAktive' },
            { title: 'Laufteam Jugend (13 bis 15)', to: '/laufteamJugend' },
            {
              title: 'Laufteam Jugend und Aktive (ab 15)',
              to: '/laufteamJugendUndAktive'
            },
            { title: 'Sportabzeichen', to: '/sportabzeichen' },
            { title: '60+', to: '/60Plus' },
            { title: 'Power Fitness', to: '/powerFitness' }
          ]
        },
        { title: 'Offene Stellen', icon: 'mdi-briefcase', to: '/offeneStellen' },
        { title: 'Mitglied werden', icon: 'mdi-account-plus', to: '/mitgliedWerden' },
        {
          title: 'Über uns',
          icon: 'mdi-stadium',
          subItems: [
            { title: 'Der Verein', to: '/derVerein' },
            { title: 'Vorstand', to: '/vorstand' },
            { title: 'Trainer', to: '/trainer' },
            { title: 'Erfolge', to: '/erfolge' },
            { title: 'Rekorde', to: '/rekorde' }
          ]
        }
      ]
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    },
    color (name) {
      return getColor(name)
    }
  }
}
</script>
