<template>
    <v-footer dark padless>
        <v-card flat tile width="100%" class="py-10 darken-3 lighten-1 text-center" :color="color('VfbBlau')">
            <v-row align="center" justify="center">
                <v-spacer />
                <div>
                    <v-img max-height="150" max-width="150" src="@/assets/VfB-Logo-T.png"></v-img><br />
                    <v-btn class="px-2" :color="color('Weis')" elevation="2" large outlined href="MITGLIED.pdf"
                        download>Mitglied werden</v-btn>
                </div>
                <div class="mx-10 px-10"> </div>
                <div class="mx-10 px-10"> </div>
                <v-divider class="mx-5 rounded-xl" :color="color('Weis')" style="border: 3px solid;" vertical
                    inset></v-divider>
                <div>
                    <router-link class="white--text pa-2 text-h6" style="text-decoration: none; color: inherit;"
                        :to="{ name: 'startseite' }">Startseite</router-link><br />
                    <router-link class="white--text pa-2 text-h6" style="text-decoration: none; color: inherit;"
                        :to="{ name: 'impressum' }">Impressum</router-link><br />
                    <router-link class="white--text pa-2 text-h6" style="text-decoration: none; color: inherit;"
                        :to="{ name: 'dsgvo' }">DSGVO</router-link>
                </div>

                <v-divider class="mx-5 rounded-xl ml-10" :color="color('Weis')" style="border: 3px solid;" vertical
                    inset></v-divider>
                <v-btn icon href="https://www.instagram.com/vfb_lc_friedrichshafen/" target="_blank">
                    <v-icon size="48px" href="https://www.instagram.com/vfb_lc_friedrichshafen/">
                        {{ 'mdi-instagram' }}
                    </v-icon>
                </v-btn>
                <v-spacer />
            </v-row>
            <v-divider inset class="mt-10" />
            <row>

                <h3 class="pt-4 white--text">
                    VfB Friedrichshafen | Leichtathletik, Teuringer Str. 2, 88045 Friedrichshafen
                </h3>
            </row>
        </v-card>
    </v-footer>
</template>

<script>
import { getColor } from '@/scripts/colorHelper'
export default {

  methods: {
    color (name) {
      return getColor(name)
    }
  },

  data: () => ({
    icons: [
      'mdi-instagram'
    ]
  })
}
</script>
